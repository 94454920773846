import base from "./base";
import { colors } from "./colors";

const theme = {
    ...base,
    colors,
};


export default theme;
