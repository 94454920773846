import React from 'react'
import { ThemeContextProvider } from './contexts/ThemeContext'

const Providers = ({ children }) => {
  return (
    <ThemeContextProvider>
      {children}
    </ThemeContextProvider>
  )
}

export default Providers
