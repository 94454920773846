import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import GlobalStyle from './style/Global';

// Import css
import './css/pe-icon-7-stroke.css';
import './css/poppins-font.css';
import './css/materialdesignicons.min.css';
import './css/bootstrap.min.css';
import './css/style.css';

const Home = React.lazy(() => import('./pages/Home'));

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <GlobalStyle />
        <React.Suspense fallback={<div>&nbsp;</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
