import React from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import theme from '../theme'

const ThemeContextProvider = ({ children }) => {
  return (
    <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
  )
}

export { ThemeContextProvider }
