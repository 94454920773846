export const baseColors = {
  failure: "#ED4B9E",
  primary: "#F43F5E",
  primaryBright: "#53DEE9",
  primaryDark: "#0098A1",
  secondary: "#7645D9",
  success: "#31D0AA",
  warning: "#FFB237",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#452a7a",
};

export const colors = {
  ...baseColors,
  ...additionalColors,
};